const points = [48.449874809683955, 12.14742761611933, 100,
48.44943360529346, 12.149208602905219, 100,
48.45089952767095, 12.150109825134223, 100,
48.45064335011283, 12.151504573821967, 100,
48.451411878909994, 12.154573020935004, 100,
48.45131225546285, 12.155517158508246, 100,
48.45072874277579, 12.157941875457709, 100,
48.452465029111046, 12.1581564521789, 100,
48.45508357817424, 12.15768438339228, 100,
48.456264727303264, 12.156311092376654, 100,
48.45841349440499, 12.156396923065131, 100,
48.45898268899606, 12.155989227294867, 100,
48.459494958671414, 12.157963333129828, 100,
48.45963725488604, 12.159508285522406, 100,
48.46073292237829, 12.15942245483393, 100,
48.46067600516885, 12.162362155914252, 100,
48.46241195134612, 12.16292005538935, 100,
48.465897893445565, 12.16240507125849, 100,
48.465399916375475, 12.16440063476557, 100,
48.464475088853554, 12.165881214141791, 100,
48.465257636315116, 12.16691118240351, 100,
48.466879605358194, 12.165945587158149, 100,
48.4687149288647, 12.170494613647406, 100,
48.47012338793565, 12.171288547515815, 100,
48.470891621861135, 12.171224174499457, 100,
48.47111924449436, 12.172576007842963, 100,
48.47258454075175, 12.172983703613227, 100,
48.472925962927356, 12.178240833282416, 100,
48.47222889020918, 12.181094703674262, 100,
48.47265567056113, 12.181244907379096, 100,
48.474817969174936, 12.183261928558295, 100,
48.474903321177024, 12.18465667724604, 100,
48.47473261702926, 12.184978542327826, 100,
48.47161716544471, 12.183669624328559, 100,
48.47063554517827, 12.18963485717768, 100,
48.46945473042251, 12.19411951065058, 100,
48.47002380120485, 12.194548664092963, 100,
48.4705501859968, 12.195471343994086, 100,
48.4705501859968, 12.195428428649848, 100,
48.47141799765089, 12.197810230255072, 100,
48.47069245121949, 12.201500949859565, 100,
48.469269781044154, 12.201307830810492, 100,
48.467221065919965, 12.197145042419379, 100,
48.46527186433911, 12.198904571533149, 100,
48.46502998738895, 12.198711452484076, 100,
48.46369252932619, 12.194334087371772, 100,
48.46225543404396, 12.196308193206733, 100,
48.459494958671414, 12.196823177337592, 100,
48.459708402843766, 12.198840198516791, 100,
48.45942381041451, 12.199076232910102, 100,
48.459566106828575, 12.199462471008246, 100,
48.459950305154166, 12.199419555664008, 100,
48.4616720471202, 12.204569396972602, 100,
48.45889731021426, 12.205384788513129, 100,
48.459551877205115, 12.206049976348822, 100,
48.459238824479925, 12.206436214446967, 100,
48.45928151360159, 12.207037029266303, 100,
48.45943804007388, 12.207745132446234, 100,
48.460590629235014, 12.20733743667597, 100,
48.46097481980647, 12.20834594726557, 100,
48.46121671608187, 12.20883947372431, 100,
48.46177165024038, 12.210448799133246, 100,
48.4621700607666, 12.210169849395697, 100,
48.46248309541484, 12.210663375854438, 100,
48.462440408985586, 12.211328563690131, 100,
48.46327990217174, 12.212422904968207, 100,
48.463834813774085, 12.213216838836615, 100,
48.46379212848158, 12.215426979064887, 100,
48.46305224437473, 12.216156539916938, 100,
48.463222987818206, 12.21660715103144, 100,
48.46188548213841, 12.21808773040766, 100,
48.461202486921124, 12.218731460571234, 100,
48.460633317219866, 12.219031867980902, 100,
48.46094636134532, 12.220662651061957, 100,
48.459395351083835, 12.221735534667914, 100,
48.4588546207695, 12.219997463226264, 100,
48.457175474133315, 12.22107034683222, 100,
48.45785852353395, 12.222615299224799, 100,
48.456734333177785, 12.223259029388373, 100,
48.456492415542456, 12.223130283355658, 100,
48.45533973333085, 12.223752555847113, 100,
48.45478472885807, 12.222636756896918, 100,
48.45327622450853, 12.223774013519233, 100,
48.450799903218595, 12.224782524108832, 100,
48.45088529561831, 12.226799545288031, 100,
48.45126955963994, 12.22759347915644, 100,
48.45085683150103, 12.22832304000849, 100,
48.45037293906601, 12.228795108795111, 100,
48.450074062023155, 12.228301582336371, 100,
48.446402000502005, 12.22982507705683, 100,
48.44523485557509, 12.231284198760932, 100,
48.444665506853184, 12.232013759612983, 100,
48.44386840792007, 12.23207813262934, 100,
48.44268697041917, 12.23306518554682, 100,
48.440779531396835, 12.233537254333442, 100,
48.44015319251458, 12.234009323120063, 100,
48.43723492091034, 12.233494338989203, 100,
48.43168262428522, 12.23430973052973, 100,
48.42910558304019, 12.228623447418158, 100,
48.427553600124014, 12.228923854827826, 100,
48.427553600124014, 12.22510438919062, 100,
48.42729730480745, 12.222915706634467, 100,
48.426499933334526, 12.22010475158686, 100,
48.42749664572091, 12.220405158996527, 100,
48.42783837118204, 12.218967494964545, 100,
48.42551744056573, 12.217293796539252, 100,
48.424065025159976, 12.217529830932563, 100,
48.42386567078649, 12.21536260604853, 100,
48.425061785298645, 12.214890537261908, 100,
48.4268986206348, 12.21383911132807, 100,
48.42786684820007, 12.210212764739936, 100,
48.42588765746226, 12.209783611297553, 100,
48.42648569444453, 12.208174285888617, 100,
48.42590189651981, 12.207380352020209, 100,
48.425830701192176, 12.205835399627631, 100,
48.42503330670882, 12.200149116516059, 100,
48.42752512293045, 12.194956359863227, 100,
48.42809466376991, 12.19463449478144, 100,
48.4280092330509, 12.193454322814887, 100,
48.42718339536297, 12.193540153503363, 100,
48.42426437875152, 12.193797645568793, 100,
48.424207420662306, 12.19287496566767, 100,
48.42739697536192, 12.192596015930121, 100,
48.43188194799699, 12.184957084655707, 100,
48.43088532161929, 12.18362670898432, 100,
48.42809466376994, 12.185064373016303, 100,
48.42688438185649, 12.184570846557563, 100,
48.426542649980576, 12.179206428527777, 100,
48.427254588795705, 12.174700317382758, 100,
48.42780989414804, 12.171288547515815, 100,
48.42711220183054, 12.171095428466742, 100,
48.42815161750281, 12.168370304107611, 100,
48.42971782014446, 12.166739521026557, 100,
48.43097074750331, 12.166760978698676, 100,
48.43114159884053, 12.165087280273383, 100,
48.428251286381766, 12.16242652893061, 100,
48.42958967810509, 12.160516796112006, 100,
48.43107041085316, 12.16116052627558, 100,
48.43275042075855, 12.15719085693354, 100,
48.430814133272904, 12.155259666442817, 100,
48.43232330486196, 12.151869354247992, 100,
48.43223788125179, 12.148157176971381, 100,
48.43396056296838, 12.147470531463568, 100,
48.43574013150702, 12.147513446807807, 100,
48.436067565328564, 12.146869716644233, 100,
48.437932474247525, 12.146354732513373, 100,
48.43800365262095, 12.145711002349799, 100,
48.43986849046962, 12.145324764251654, 100,
48.43988272560976, 12.142599639892524, 100,
48.441889840435394, 12.142492351531928, 100,
48.44411038570427, 12.143050251007026, 100,
48.445818431391295, 12.144616661071723, 100,
48.44556222820017, 12.146161613464301, 100,
48.44754065263087, 12.14867216110224, 100,
48.44803880490945, 12.146504936218207, 100,
48.449874809683955, 12.14742761611933, 100]
  
export default points;